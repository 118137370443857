import { version } from './version';

export enum Keys {
  ACCOUNT_SETTINGS = 'RBI::ACCOUNT_SETTINGS',
  APP_RATING_SHOWN = 'RBI::APP_RATING_SHOWN',
  APPLIED_LOYALTY_OFFERS = 'RBI::APPLIED_LOYALTY_OFFERS',
  APPLIED_LOYALTY_REWARDS = 'RBI::APPLIED_LOYALTY_REWARDS',
  AUTH_REDIRECT = 'RBI::AUTH_REDIRECT',
  BRAND = 'RBI::BRAND',
  DELIVERY_SURCHARGE_FEE = 'RBI::DELIVERY_SURCHARGE_FEE',
  DELIVERY_ADDRESS = 'RBI::DELIVERY_ADDRESS',
  DETERMINING_LOCALE_COMPLETE = 'RBI::DETERMINING_LOCALE_COMPLETE',
  DISABLE_CHICKEN_SANDWICH = 'RBI::DISABLE_CHICKEN_SANDWICH',
  FIRST_DATA = 'RBI::FIRST_DATA',
  GEO_PERMISSION = 'RBI::GEO_PERMISSION',
  GUEST = 'RBI::GUEST',
  HOSTNAME = 'RBI::HOSTNAME',
  INVITATION_CODE_SIGN_UP_MODAL = 'RBI::INVITATION_CODE_SIGN_UP_MODAL',
  IN_RESTAURANT_CART_LAST_UPDATE = 'RBI::IN_RESTAURANT_CART_LAST_UPDATE',
  IN_RESTAURANT_REDEMPTION = 'RBI::LOYALTY_IN_RESTAURANT_REDEMPTION',
  IN_RESTAURANT_REDEMPTION_LAST_MOD = 'RBI::IN_RESTAURANT_REDEMPTION_LAST_MOD',
  IN_RESTAURANT_REDEMPTION_SHORT_CODE = 'RBI::LOYALTY_IN_RESTAURANT_REDEMPTION_SHORT_CODE',
  LANGUAGE = 'RBI::LANGUAGE',
  LAST_COOKIE_VERSION = 'RBI::COOKIE_VERSION',
  LAST_TIME_COOKIES_ACCEPTED = 'RBI::LAST_TIME_COOKIES_ACCEPTED',
  LAST_TIME_COOKIES_IGNORED = 'RBI::LAST_TIME_COOKIES_IGNORED',
  LAST_TIME_STORE_UPDATED = 'RBI::LAST_TIME_STORE_UPDATED',
  LAUNCH_DARKLY_USER_ATTRIBUTES = 'RBI::LAUNCH_DARKLY_USER_ATTRIBUTES',
  LOGIN = 'RBI::LOGIN',
  LOYALTY_CARD = 'RBI::LOYALTY',
  OFFER = 'RBI::OFFER',
  OFFER_REDEMPTION = 'RBI::OFFER_REDEMPTION',
  OFFERS_REDEEMED_NO_AUTH = 'RBI::OFFERS_REDEEMED_NO_AUTH',
  ONETRUST_COOKIE_CONSENT_GROUPS = 'RBI::ONETRUST_COOKIE_CONSENT_GROUPS',
  USER_CENTRICS_COOKIE_CONSENT_GROUPS = 'RBI::USER_CENTRICS_COOKIE_CONSENT_GROUPS',
  ORDER = 'RBI::ORDER',
  ORDER_LAST_UPDATE = 'RBI::ORDER_LAST_UPDATE',
  OTP = 'RBI::OTP',
  PAYMENT = 'RBI::PAYMENT',
  PREORDER_SLOT = 'RBI::PREORDER_SLOT',
  QUEST_COMPLETED_UNLOCKED_INCENTIVE = 'RBI::QUEST_COMPLETED_UNLOCKED_INCENTIVE',
  QUOTE_ID = 'RBI::QUOTE_ID',
  REDEEM_LAST_CODE_STATE = 'RBI::REDEEM_LAST_CODE_STATE',
  REGION = 'RBI::REGION',
  REGION_REDIRECT = 'RBI::REGION_REDIRECT',
  SELECTED_LOYALTY_OFFER = 'RBI::SELECTED_LOYALTY_OFFER',
  SERVICE_MODE = 'RBI::SERVICE_MODE',
  STORAGE_VERSION = 'RBI::STORAGE_VERSION',
  STORE = 'RBI::STORE',
  STORE_NUMBER_PRESET = 'RBI::STORE_PRESET',
  TABBED_MENU_PREVIOUS_ACTIVE_TAB = 'RBI::TABBED_MENU_PREVIOUS_ACTIVE_TAB',
  TABLE_NUMBER = 'RBI::TABLE_NUMBER',
  USER = 'RBI::USER',
  USER_SIGNED_IN_SUCCESSFULLY = 'RBI::USER_SIGNED_IN_SUCCESSFULLY',
  VEHICLE_DESCRIPTION = 'RBI::VEHICLE_DESCRIPTION',
  FREE_MODAL_ITEMS_SHOWED_IN_CART = 'RBI::FREE_MODAL_ITEMS_SHOWED_IN_CART',
  FREE_REFILL_ORDER_INFOS = 'RBI::FREE_REFILL_ORDER_INFOS',
  LIVE_UPDATES_SHOULD_RELOAD = 'RBI::LIVE_UPDATES_SHOULD_RELOAD',
  LIVE_UPDATES_BUILD_ID = 'RBI::LIVE_UPDATES_BUILD_ID',
  WAITING_PAYMENT = 'RBI::WAITING_PAYMENT',
  FEATURE_FLAG_OVERRIDES = 'RBI::FEATURE_FLAG_OVERRIDES',
  SOCIAL_LOGIN_USER_INFO = 'RBI::SOCIAL_LOGIN_USER_INFO',
  BRAME_CONSENT_CAMPAIGN_ID = 'RBI::BRAME_CONSENT_CAMPAIGN_ID',
}

export const APP_KEY_PREFIX = 'RBI::';

export const LOCAL_STORAGE_VERSION = version.localStorage;
export const COOKIE_VERSION = version.cookie;
